import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/atlassian/pipelines/agent/build/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { PackageMetadata, BackgroundBox } from '@happeo/docs-tools';
import pkg from "../package.json";
import { DebugHappeoThemeProvider } from "../src/Theme/HappeoThemeProvider";
import { ColorsExample, AnimationsExample } from "../src/examples/DesignSystemExamples";
import RectangleExample, { CircleExample } from "../src/old/examples/RectangleExample";
import { Line300, Line400, Line600 } from '../src/old/Lines';
import { shadow100, shadow200, shadow300, shadow400, shadow500 } from '../src/old/shadows';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "theme"
    }}>{`Theme`}</h1>
    <PackageMetadata pkg={pkg} mdxType="PackageMetadata" />
    <DebugHappeoThemeProvider mdxType="DebugHappeoThemeProvider" />
    <h2 {...{
      "id": "colors"
    }}>{`Colors`}</h2>
    <ColorsExample mdxType="ColorsExample" />
    <h2 {...{
      "id": "border-radius-rounded-rectangles"
    }}>{`Border radius (rounded rectangles)`}</h2>
    <RectangleExample borderRadius="var(--radius-sharp)" name='radius-sharp' mdxType="RectangleExample" />
    <RectangleExample borderRadius="var(--radius-sm)" name='radius-sm' mdxType="RectangleExample" />
    <RectangleExample borderRadius="var(--radius-md)" name='radius-md' mdxType="RectangleExample" />
    <RectangleExample borderRadius="var(--radius-lg)" name='radius-lg' mdxType="RectangleExample" />
    <h2 {...{
      "id": "lines"
    }}>{`Lines`}</h2>
    <p>{`Each line has its own component `}<inlineCode parentName="p">{`<Line300 />`}</inlineCode>{`, `}<inlineCode parentName="p">{`<Line400 />`}</inlineCode>{`, `}<inlineCode parentName="p">{`<Line600 />`}</inlineCode>{`.`}</p>
    <Line300 mdxType="Line300" />
    <p>Line300</p>
    <Line400 mdxType="Line400" />
    <p>Line400</p>
    <Line600 mdxType="Line600" />
    <p>Line600</p>
    <h2 {...{
      "id": "shadows"
    }}>{`Shadows`}</h2>
    <div>
      <RectangleExample name='shadow200 old' shadow={shadow200} mdxType="RectangleExample" />
      <RectangleExample name='shadow300 old' shadow={shadow300} mdxType="RectangleExample" />
      <RectangleExample name='shadow400 old' shadow={shadow400} mdxType="RectangleExample" />
      <RectangleExample name='shadow500 old' shadow={shadow500} mdxType="RectangleExample" />
      <CircleExample name='shadow100 old' shadow={shadow100} mdxType="CircleExample" />
    </div>
    <div>
      <RectangleExample name='shadow100 new' shadow="var(--box-shadow-shadow100)" mdxType="RectangleExample" />
      <RectangleExample name='shadow200 new' shadow="var(--box-shadow-shadow200)" mdxType="RectangleExample" />
      <RectangleExample name='shadow300 new' shadow="var(--box-shadow-shadow300)" mdxType="RectangleExample" />
      <RectangleExample name='shadow400 new' shadow="var(--box-shadow-shadow400)" mdxType="RectangleExample" />
      <CircleExample name='shadow300 new' shadow="var(--box-shadow-shadow300)" mdxType="CircleExample" />
    </div>
    <BackgroundBox style={{
      backgroundColor: "var(--color-surface-over)"
    }} mdxType="BackgroundBox">
  <RectangleExample name='shadow200 old' shadow={shadow200} mdxType="RectangleExample" />
  <RectangleExample name='shadow300 old' shadow={shadow300} mdxType="RectangleExample" />
  <RectangleExample name='shadow400 old' shadow={shadow400} mdxType="RectangleExample" />
  <RectangleExample name='shadow500 old' shadow={shadow500} mdxType="RectangleExample" />
  <CircleExample name='shadow100 old' shadow={shadow100} mdxType="CircleExample" />
    </BackgroundBox>
    <BackgroundBox style={{
      backgroundColor: "var(--color-surface-over)"
    }} mdxType="BackgroundBox">
  <RectangleExample name='shadow100 new' shadow="var(--box-shadow-shadow100)" mdxType="RectangleExample" />
  <RectangleExample name='shadow200 new' shadow="var(--box-shadow-shadow200)" mdxType="RectangleExample" />
  <RectangleExample name='shadow300 new' shadow="var(--box-shadow-shadow300)" mdxType="RectangleExample" />
  <RectangleExample name='shadow400 new' shadow="var(--box-shadow-shadow400)" mdxType="RectangleExample" />
  <CircleExample name='shadow300 new' shadow="var(--box-shadow-shadow300)" mdxType="CircleExample" />
    </BackgroundBox>
    <h2 {...{
      "id": "animations"
    }}>{`Animations`}</h2>
    <p>{`Animations have three parameters:  easing, duration, and keyframes.`}</p>
    <p>{`Easing is linear by default (if unspecified), but usually we want it to be smoother and nicer, so we use e.g.
`}<inlineCode parentName="p">{`--animatino-easing-ease-out`}</inlineCode>{`.  Duration is usually less than one second - you can use e.g.
`}<inlineCode parentName="p">{`--animation-duration-fast-lg`}</inlineCode>{`.  Keyframes really depend on your animation goal, but we have several reusable ones,
e.g. `}<inlineCode parentName="p">{`animationKeyframes.dropFade`}</inlineCode>{`.  Note that the keyframes can't be used as CSS variables, you'll need to import them.`}</p>
    <p>{`Example:`}</p>
    <pre><code parentName="pre" {...{}}>{`export const ElementDropFade = styled.div\`
  margin: var(--space-md);
  width: var(--space-xxl);
  height: var(--space-xxl);
  border-radius: var(--radius-md);
  background-color: green;
  animation: \${animationKeyframes.dropFade} var(--animation-duration-fast-lg) var(--animation-easing-ease-out) infinite;
\`;
`}</code></pre>
    <p>{`Examples of all animation constants:`}</p>
    <AnimationsExample mdxType="AnimationsExample" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      